<template>
    <v-expansion-panel
        v-on="$listeners"
    >
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Configure colors</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pl-8">
            <color-input-row v-model="value.primary" title="Primary" />
            <color-input-row v-model="value.secondary" title="Secondary" />
            <color-input-row v-model="value.black" title="Black" />
            <color-input-row v-model="value.white" title="White" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";
import ColorInputRow from "components/create_store/fields/ColorInputRow";
export default {
    name: "DefaultColorsSection",
    components: {
        FieldRow,
        ColorInputRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
