var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Configure colors"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticClass: "pl-8" },
        [
          _c("color-input-row", {
            attrs: { title: "Primary" },
            model: {
              value: _vm.value.primary,
              callback: function ($$v) {
                _vm.$set(_vm.value, "primary", $$v)
              },
              expression: "value.primary",
            },
          }),
          _c("color-input-row", {
            attrs: { title: "Secondary" },
            model: {
              value: _vm.value.secondary,
              callback: function ($$v) {
                _vm.$set(_vm.value, "secondary", $$v)
              },
              expression: "value.secondary",
            },
          }),
          _c("color-input-row", {
            attrs: { title: "Black" },
            model: {
              value: _vm.value.black,
              callback: function ($$v) {
                _vm.$set(_vm.value, "black", $$v)
              },
              expression: "value.black",
            },
          }),
          _c("color-input-row", {
            attrs: { title: "White" },
            model: {
              value: _vm.value.white,
              callback: function ($$v) {
                _vm.$set(_vm.value, "white", $$v)
              },
              expression: "value.white",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }